import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'

import { useAppConfig } from '../../../config';
import Layout from '../../../components/layout'
import Seo from '../../../components/Seo';
import StructuredData from '../../../components/structured-data'
import SfForm from '../../../components/salesforce/Form';
import SfRecaptcha from '../../../components/salesforce/Recaptcha';

function CcpaPage({ data }) {
  const { wpPage } = data

  const {sfForms} = useAppConfig();
  const {servlet, orgId, recordTypes} = sfForms;
  const recordType = recordTypes.ccpa;

  return (
    <Layout>
      <Seo post={wpPage} />
      <StructuredData pageType="webPage" />
      <div className="py-10 lg:py-20 relative">
        <div className="container relative z-30 text-white">
          <h1 className="text-white mb-2 text-3xl lg:text-4xl">
            California Consumer Privacy Act
          </h1>
        </div>
        <div
          className="absolute w-full h-full top-0 left-0 z-20"
          style={{
            backgroundColor: `#2e3c4b`,
          }}
        ></div>
      </div>

      <div className="py-10 container">
        <p className="mb-4">
          Liquid Web, LLC, its applicable affiliate, or one or more of its
          family of brands, including Nexcess and iThemes, (“Liquid Web”) is
          committed to individual privacy rights and providing the highest level
          of customer service. If you are a California resident wishing to
          exercise your rights under California law, including the CCPA, please
          complete the following form, select the options that apply to your
          request(s), and click the “Submit” button. We will promptly respond to
          your request.
        </p>
        <p className="mb-4">
          You are not required to provide all of the information listed below,
          but we may not be able to respond to your request or provide you with
          information if we cannot verify your identity or authority. We may
          contact you to request more information. We will only use the
          information you provide to verify your identity or authority to make a
          request and to respond to your request.
        </p>
        <p className="mb-4">
          For more on our general privacy practices, please review our{' '}
          <Link to="/about-us/policies/privacy-policy">Privacy Policy</Link>.
        </p>
      </div>
      <div
        id="ccpa-form"
        className="py-10"
        style={{ backgroundColor: `#f4f4f4` }}
      >
        <div className="container">
          <SfForm
            action={servlet}
            method="POST"
          >
            <input type="hidden" name="orgid" value={orgId} />
            <input
              type="hidden"
              name="retURL"
              value="https://www.liquidweb.com/form-success-abuse"
            />
            <input
              type="hidden"
              name="RecordTypeId"
              id="RecordTypeId"
              value={recordType}
            />
            <input
              type="hidden"
              name="subject"
              id="subject"
              maxLength="80"
              size="20"
              value="CCPA Request"
            />
            <label htmlFor="name" className="block w-full mb-5">
              <span className="block font-semibold">
                Requestor&apos;s Name<span className="text-red-600">*</span>
              </span>
              <input
                id="name"
                type="text"
                aria-label="name"
                name="name"
                required={true}
                maxLength="80"
                size="20"
                className="form-input w-full"
              />
            </label>
            <label htmlFor="company" className="block w-full mb-5">
              <span className="block font-semibold">
                Requestor&apos;s Company (if any)
              </span>
              <input
                id="company"
                type="text"
                aria-label="company"
                name="company"
                required={false}
                maxLength="80"
                size="20"
                className="form-input w-full"
              />
            </label>

            <label htmlFor="email" className="block w-full mb-5">
              <span className="block font-semibold">
                Requestor&apos;s Email
                <span className="text-red-600">*</span>
              </span>
              <input
                id="email"
                type="email"
                aria-label="email"
                name="email"
                required={true}
                maxLength="80"
                size="20"
                className="form-input w-full"
              />
            </label>
            <label htmlFor="phone" className="block w-full mb-5">
              <span className="block font-semibold">
                Requestor&apos;s Phone Number
              </span>
              <input
                id="phone"
                type="tel"
                aria-label="phone"
                name="phone"
                required={false}
                maxLength="40"
                size="20"
                className="form-input w-full"
              />
            </label>
            <label htmlFor="00N3a00000Cm1fF" className="block w-full mb-5">
              <span className="block font-semibold">
                Requestor&apos;s Mailing Address:
              </span>
              <input
                id="00N3a00000Cm1fF"
                type="text"
                aria-label="00N3a00000Cm1fF"
                name="00N3a00000Cm1fF"
                required={false}
                maxLength="100"
                size="20"
                className="form-input w-full"
              />
            </label>
            <p className="block w-full mb-2 font-semibold">
              Please indicate if you are exercising a right for yourself or
              acting as an authorized agent behalf of another:
            </p>
            <label htmlFor="00N3a00000COy8P" className="inline-block mb-5">
              <span className="inline-block">
                Exercising a right for myself:{'\u00A0'}
                <input
                  id="00N3a00000COy8P"
                  type="checkbox"
                  value="1"
                  aria-label="00N3a00000COy8P"
                  name="00N3a00000COy8P"
                  required={false}
                  style={{
                    width: `13px`,
                    height: `13px`,
                    display: `inline-block`,
                    margin: `0 5px 0 0`,
                    padding: `0`,
                    verticalAlign: `middle`,
                    borderRadius: `0`,
                  }}
                />
                {'\u00A0'}
              </span>
            </label>
            <label htmlFor="00N3a00000COy8U" className="inline-block mb-5">
              <span className="inline-block">
                Acting as an authorized agent:{'\u00A0'}
                <input
                  id="00N3a00000COy8U"
                  type="checkbox"
                  value="1"
                  aria-label="00N3a00000COy8U"
                  name="00N3a00000COy8U"
                  required={false}
                  style={{
                    width: `13px`,
                    height: `13px`,
                    display: `inline-block`,
                    margin: `0 5px 0 0`,
                    padding: `0`,
                    verticalAlign: `middle`,
                    borderRadius: `0`,
                  }}
                />
              </span>
            </label>
            <label htmlFor="00N3a00000Cm1fP" className="block w-full mb-5">
              <span className="block font-semibold">
                Name of California resident on whose behalf you are acting: (if
                acting on behalf of another)
              </span>
              <textarea
                id="00N3a00000Cm1fP"
                aria-label="00N3a00000Cm1fP"
                name="00N3a00000Cm1fP"
                required={false}
                rows="2"
                wrap="soft"
                className="form-input w-full"
              />
            </label>
            <p className="block w-full mb-2 font-semibold">
              Preferred Method of Contact (check all that apply):
            </p>
            <label htmlFor="00N3a00000COy8F" className="inline-block mb-5">
              <span className="inline-block">
                Telephone:{'\u00A0'}
                <input
                  id="00N3a00000COy8F"
                  type="checkbox"
                  value="1"
                  aria-label="00N3a00000COy8F"
                  name="00N3a00000COy8F"
                  required={false}
                  style={{
                    width: `13px`,
                    height: `13px`,
                    display: `inline-block`,
                    margin: `0 5px 0 0`,
                    padding: `0`,
                    verticalAlign: `middle`,
                    borderRadius: `0`,
                  }}
                />
                {'\u00A0'}
              </span>
            </label>
            <label htmlFor="00N3a00000COy8A" className="inline-block mb-5">
              <span className="inline-block">
                E-Mail:{'\u00A0'}
                <input
                  id="00N3a00000COy8A"
                  type="checkbox"
                  value="1"
                  aria-label="00N3a00000COy8A"
                  name="00N3a00000COy8A"
                  required={false}
                  style={{
                    width: `13px`,
                    height: `13px`,
                    display: `inline-block`,
                    margin: `0 5px 0 0`,
                    padding: `0`,
                    verticalAlign: `middle`,
                    borderRadius: `0`,
                  }}
                />
              </span>
            </label>
            <label htmlFor="00N3a00000COy8K" className="inline-block mb-5">
              <span className="inline-block">
                Mail:{'\u00A0'}
                <input
                  id="00N3a00000COy8K"
                  type="checkbox"
                  value="1"
                  aria-label="00N3a00000COy8K"
                  name="00N3a00000COy8K"
                  required={false}
                  style={{
                    width: `13px`,
                    height: `13px`,
                    display: `inline-block`,
                    margin: `0 5px 0 0`,
                    padding: `0`,
                    verticalAlign: `middle`,
                    borderRadius: `0`,
                  }}
                />
              </span>
            </label>
            <p className="block w-full mb-2 font-semibold">
              Rights You Are Exercising (check all that apply):
            </p>
            <label htmlFor="00N3a00000COy8Z" className="inline-block mb-5">
              <span className="inline-block">
                Right to know:{'\u00A0'}
                <input
                  id="00N3a00000COy8Z"
                  type="checkbox"
                  value="1"
                  aria-label="00N3a00000COy8Z"
                  name="00N3a00000COy8Z"
                  required={false}
                  style={{
                    width: `13px`,
                    height: `13px`,
                    display: `inline-block`,
                    margin: `0 5px 0 0`,
                    padding: `0`,
                    verticalAlign: `middle`,
                    borderRadius: `0`,
                  }}
                />
                {'\u00A0'}
              </span>
            </label>
            <label htmlFor="00N3a00000COy8e" className="inline-block mb-5">
              <span className="inline-block">
                Right to delete:{'\u00A0'}
                <input
                  id="00N3a00000COy8e"
                  type="checkbox"
                  value="1"
                  aria-label="00N3a00000COy8e"
                  name="00N3a00000COy8e"
                  required={false}
                  style={{
                    width: `13px`,
                    height: `13px`,
                    display: `inline-block`,
                    margin: `0 5px 0 0`,
                    padding: `0`,
                    verticalAlign: `middle`,
                    borderRadius: `0`,
                  }}
                />
              </span>
            </label>
            <label htmlFor="00N3a00000COy8j" className="inline-block mb-5">
              <span className="inline-block">
                Shine the light request:{'\u00A0'}
                <input
                  id="00N3a00000COy8j"
                  type="checkbox"
                  value="1"
                  aria-label="00N3a00000COy8j"
                  name="00N3a00000COy8j"
                  required={false}
                  style={{
                    width: `13px`,
                    height: `13px`,
                    display: `inline-block`,
                    margin: `0 5px 0 0`,
                    padding: `0`,
                    verticalAlign: `middle`,
                    borderRadius: `0`,
                  }}
                />
              </span>
            </label>
            <label htmlFor="00N3a00000Cm1fK" className="block w-full mb-5">
              <span className="block font-semibold">Additional Comments</span>
              <textarea
                id="00N3a00000Cm1fK"
                aria-label="00N3a00000Cm1fK"
                name="00N3a00000Cm1fK"
                required={false}
                rows="2"
                wrap="soft"
                className="form-input w-full"
              />
            </label>
            <input type="hidden" id="external" name="external" value="1" />
            <div className="w-1/2">
              <SfRecaptcha Warning={(
                <div className="text-red-600 pt-5 font-semibold">Please verify you are not a robot!</div>
              )} />
              <button
                className="btn btn-primary p-3 w-full font-semibold mt-8"
                type="submit"
              >
                Submit
              </button>
            </div>
          </SfForm>
        </div>
      </div>
    </Layout>
  )
}

CcpaPage.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object,
}

export default CcpaPage

export const query = graphql`
  {
    wpPage(databaseId: { eq: 73309 }) {
      title
      date
      uri
      modified
      chatDepartment
      seo {
        ...postSeoFields
      }
    }
  }
`
